<template>
    <div class="tAndC">
        <header class="header">
            <img src="../assets/images/backIcon.svg" alt="" @click="$router.go(-1)">
            <h4>{{ $t('TermsAndConditions') }}</h4>
        </header>
        <section>
            <iframe width="100%" height="150"
            src="https://www.youtube.com/embed/8UY0hoH8F4w?rel=0&amp;modestbranding=1&amp;fs=0" frameborder="0" allowfullscreen>
            </iframe>
            <!-- <p class="desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit iure ab doloribus voluptatum iste asperiores a illo? Esse quidem libero inventore! Explicabo nulla dignissimos blanditiis officiis sapiente, consectetur nostrum nisi!</p> -->
            <p class="subHeading">{{ $t('TermsAndConditions') }} :</p>
            <ul class="list">
                <li v-for="(item, i) in $t('TermsAndConditionsPoints')" :key="i">
                    <img src="../assets/images/tickIcon.svg" alt="">
                    <span>{{item}}</span>
                </li>
            </ul>

            <p class="subHeading">{{ $t('PointsToNote') }} :</p>
            <ul class="list">
                <li v-for="(item, i) in $t('PointsToNoteList')" :key="i">
                    <img src="../assets/images/tickIcon.svg" alt="">
                    <span>{{item}}</span>
                </li>
            </ul>
            <!-- <strong class="readMore">For read more click on this url</strong>
            <a class="link" target="_blank" href="https://www.mall91.com/#/detail/2483438">https://www.mall91.com/#/detail/2483438</a> -->
        </section>
    </div>
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>
.tAndC{
    font-family: "Roboto", sans-serif;
    .header{
        display: flex;
        align-items: center;
        padding: .5em;
        box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.07);
        position: sticky;
        span{
            font-weight: 600;
        }
        h4{
            padding-left: .6em;
        }
    }

    section{
        padding: 1em;

        iframe{
            border-radius: .6em;
            border: none;
        }

        .desc{
            font-size: .857em;
            padding-top: .7em;
            color: #262626;
        }

        .subHeading{
            font-size: .9em;
            font-weight: bold;
            margin: 1em 0 .5em 0;
        }

        .list{
            list-style-type: none;
            line-height: 1.7em;
            font-size: .857em;
            li{
                display: flex;
                align-items: flex-start;
                img{
                    padding-top: .3em;
                    height: 1.4em;
                }
                span{
                    padding-left: .5em;
                }
            }
        }

        .readMore{
            font-size: .857em;
            display: block;
            margin-top: 1em;
        }
        .link{
            color: #515ED5;
            font-size: .857em;
            text-decoration: none;
            margin-top: .5em;
        }
    }
}
</style>